<template>
  <panel :title="title" noButton="true" v-has-perms="rolePerm">
    <div>
      <b-form>
        <b-form-row>
          <form-thux-horizontal-input
            :validator="$v.form.code"
            :external-errors="errors['code']"
            :label-form="'Code' | translate"
            class-form="col-12 mb-2"
            type="text"
            :value="form.code"
            :disabled="!formEditable"
            @change="$set(form, 'code', $event)"
          />
        </b-form-row>
        <b-form-row>
          <form-thux-horizontal-input
            :validator="$v.form.name"
            :external-errors="errors['name']"
            :label-form="'Name' | translate"
            class-form="col-12 mb-2"
            type="text"
            :value="form.name"
            :disabled="!formEditable"
            @change="$set(form, 'name', $event)"
          />
        </b-form-row>
        <b-form-row>
          <form-thux-horizontal-input
            :validator="$v.form.fee"
            :external-errors="errors['fee']"
            :label-form="'Fee' | translate"
            class-form="col-12 mb-2"
            type="number"
            :value="form.fee"
            :disabled="!formEditable"
            @change="$set(form, 'fee', $event)"
          />
        </b-form-row>
        <b-form-row>
          <form-thux-horizontal-input
            :validator="$v.form.discount"
            :external-errors="errors['discount']"
            :label-form="'Discount' | translate"
            class-form="col-12 mb-2"
            type="number"
            :value="form.discount"
            :disabled="!formEditable"
            @change="$set(form, 'discount', $event)"
          />
        </b-form-row>
        <b-form-row>
          <form-thux-horizontal-input
            :validator="$v.form.description"
            :external-errors="errors['description']"
            :label-form="'Description' | translate"
            class-form="col-12 mb-2"
            type="text"
            :value="form.description"
            :disabled="!formEditable"
            @change="$set(form, 'description', $event)"
          />
        </b-form-row>
        <b-form-row>
        <form-thux-horizontal-select
          :validator="$v.form.electronic_invoice_code"
          :external-errors="errors['electronic_invoice_code']"
          :label-form="'Electronic invoice code' | translate"
          class-form="col-lg-12 mb-2"
          :disabled="!formEditable"
          label-search="text"
          :value="form.electronic_invoice_code ? { value: form.electronic_invoice_code, text: form.electronic_invoice_code_name } : null"
          :placeholder="$t('Select electronic invoice code')"
          :options="electronicInvoiceCodeOptions"
          @select="selectElectronicInvoiceCode"
          @clear="clearElectronicInvoiceCode"
        />
        </b-form-row>
      </b-form>
      <div class="mt-3 f-w-600 d-flex justify-content-end">
        <div class="mt-3 f-w-600 d-flex justify-content-end">
          <b-button
            class="mr-3"
            variant="default"
            @click.prevent="formEditable ? cancel() : closeForm()">
            {{ formEditable ? 'Cancel' : 'Close' | translate }}
          </b-button>
          <b-button
            variant="primary"
            v-has-perms="editRolePerm"
            @click.prevent="formEditable ? onSubmit() : makeFormEditable()"
            :disabled="$v.form.$invalid">
            {{ formEditable ? 'Save' : 'Edit' | translate }}
          </b-button>
        </div>
      </div>
    </div>
  </panel>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { mapActions, mapGetters } from 'vuex'
import { TYPES as PAYMENT_FORM_TYPES } from './store'

import ThuxDetailPanelMixin from '../../../components/thux-detail-panel/ThuxDetailPanelMixin'
import ElectronicInvoiceCodeMixin from './mixins/ElectronicInvoiceCodeMixin'

export default {
  name: 'PaymentFormEdit',
  mixins: [ThuxDetailPanelMixin, ElectronicInvoiceCodeMixin],
  data () {
    return {
      pageName: 'Payment form',
      rolePerm: 'payment_paymentform_retrieve',
      editRolePerm: 'payment_paymentform_update'
    }
  },
  computed: {
    ...mapGetters({
      ...PAYMENT_FORM_TYPES.GENERIC.payment.paymentform.DETAIL.GETTERS
    })
  },
  components: { },
  methods: {
    ...mapActions({
      ...PAYMENT_FORM_TYPES.GENERIC.payment.paymentform.DETAIL.ACTIONS
    })
  },
  validations: {
    form: {
      code: { required },
      name: { required },
      fee: {},
      discount: {},
      description: {},
      electronic_invoice_code: { required }
    }
  }
}
</script>
