<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TYPES as PAYMENT_FORM_TYPES } from './store'
import { ROUTES as PAYMENT_FORM_ROUTES } from './router'
import { hasPerm } from '@/components/PermissionHelper'

import ThuxListMixin from '../../../components/thux-list/ThuxListMixin'

import PaymentFormTable from './PaymentFormTable'
import PaymentFormCommonFilters from './PaymentFormCommonFilters'
import PaymentFormEdit from './PaymentFormEdit.vue'

export default {
  name: 'PaymentFormList',
  mixins: [ThuxListMixin],
  components: {
    'component-table': PaymentFormTable,
    'common-filters': PaymentFormCommonFilters,
    'component-edit': PaymentFormEdit,
    'component-detail': PaymentFormEdit
  },
  data () {
    return {
      PAYMENT_FORM_ROUTES,
      title: this.$t('Payment forms'),
      rolePerm: ['payment_paymentform_list'],
      actionEnablePermission: ['payment_paymentform_enable'],
      actionDisablePermission: ['payment_paymentform_disable'],
      searchFields: [
        { key: 'filter__code__icontains', placeholder: this.$t('Code'), type: 'text', col: 6 },
        { key: 'filter__name__icontains', placeholder: this.$t('Name'), type: 'text', col: 6 },
        { key: 'filter__fee', placeholder: this.$t('Fee'), type: 'number', col: 6 },
        { key: 'filter__discount', placeholder: this.$t('Discount'), type: 'number', col: 6 },
        { key: 'filter__description__icontains', placeholder: this.$t('Description'), type: 'text', col: 12 }
      ]
    }
  },
  computed: {
    ...mapGetters({
      ...PAYMENT_FORM_TYPES.GENERIC.payment.paymentform.LIST.GETTERS
    }),
    showList () {
      return this.$route.name === this.PAYMENT_FORM_ROUTES.PAYMENT_FORM_LIST
    },
    canSeeActionSelect () {
      return true
    },
    canAddInstance () {
      return hasPerm('payment_paymentform_create')
    }
  },
  methods: {
    ...mapMutations({
      setSelectedList: PAYMENT_FORM_TYPES.GENERIC.payment.paymentform.LIST.MUTATIONS.setSelectedList,
      setSelectAll: PAYMENT_FORM_TYPES.GENERIC.payment.paymentform.LIST.MUTATIONS.setSelectAll
    }),
    ...mapActions({
      ...PAYMENT_FORM_TYPES.GENERIC.payment.paymentform.LIST.ACTIONS
    })
  }
}
</script>
