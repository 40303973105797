<script>
import { mapActions, mapGetters } from 'vuex'
import { TYPES as PAYMENT_FORM_TYPES } from '../store'

export default {
  name: 'ElectronicInvoiceCodeMixin',
  created () {
    if (!this.electronicInvoicePaymentCodeList || (this.electronicInvoicePaymentCodeList && this.electronicInvoicePaymentCodeList.length === 0)) {
      this.getElectronicinvoicepaymentcodelist()
    }
  },
  computed: {
    ...mapGetters({
      electronicInvoicePaymentCodeList: PAYMENT_FORM_TYPES.GENERIC.payment.paymentform.LIST.GETTERS.electronicInvoicePaymentCodeList
    }),
    electronicInvoiceCodeOptions () {
      const options = []
      if (this.electronicInvoicePaymentCodeList && this.electronicInvoicePaymentCodeList.length > 0) {
        this.electronicInvoicePaymentCodeList.forEach((documentStatus) => {
          options.push({
            value: documentStatus[0],
            text: documentStatus[1]
          })
        })
      }
      return options
    }
  },
  methods: {
    ...mapActions({
      getElectronicinvoicepaymentcodelist: PAYMENT_FORM_TYPES.GENERIC.payment.paymentform.LIST.ACTIONS.getElectronicinvoicepaymentcodelist
    }),
    selectElectronicInvoiceCode (item) {
      this.$set(this.form, 'electronic_invoice_code', item.value)
      this.$set(this.form, 'electronic_invoice_code_name', item.text)
    },
    clearElectronicInvoiceCode () {
      this.$set(this.form, 'electronic_invoice_code', null)
      this.$set(this.form, 'electronic_invoice_code_name', null)
    }
  }
}
</script>
